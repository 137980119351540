import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import LayoutSimplePage from "../components/LayoutSimplePage"
import { missionPage } from "../../data"

const Introduccion = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(name: { regex: "/intro-hero/" }) {
        sharp: childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <LayoutSimplePage
      inverseHeader={true}
      revealedFooter={true}
      title={missionPage.title}
      lead={missionPage.description}
      tagName={missionPage.tag}
      image={image.sharp.fluid}
      body={missionPage.body}
    />
  )
}

export default Introduccion
